import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStoryblokCache } from '@libs/storyblok';

import { SHDecisionItem } from '@common/hooks/useSortingHat';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

import { useHubRedirect } from '@hub/hooks/useHubRedirect';
import { OfferDetail, SBContent, StoryblokProduct } from '@hub/types';

export const useOfferDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { productContent: productList, sortingHatContent: suggestionList } =
    useStoryblokCache({
      getProduct: true,
      getSH: true,
    });

  const { handleOfferRedirect, loadingRedirect, redirectModal } =
    useHubRedirect();

  const [offer, setOffer] = useState<OfferDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const slug = getProductSlugFromUrl();
    if (!isValidProduct(slug)) {
      navigate('404');
      return;
    }

    getOfferBySlug(slug);
  }, [productList, suggestionList]);

  function getProductSlugFromUrl() {
    const pathname = location.pathname.split('/ofertas/');
    const slug = pathname.length > 1 ? pathname[1] : '';
    return slug;
  }

  function getOfferBySlug(slug: string) {
    try {
      const checkProduct = productList && productList.length > 0;
      const checkSuggestion = suggestionList && suggestionList.length > 0;
      if (!checkProduct || !checkSuggestion) return;

      const sbProduct = productList.find((x) => x?.product_slug === slug);
      const product = (sbProduct as SBContent) || null;
      if (!product) throw new Error('[OFFER_DETAIL]: Product not found');

      const sbSuggestion = suggestionList.find((x) => x.slug === slug);
      const suggestion = (sbSuggestion as SBContent) || null;
      if (!suggestion) throw new Error('[OFFER_DETAIL]: Suggestion not found');

      const formattedOffer = getOfferDetails({ product, suggestion });
      setOffer(formattedOffer);
      setError(false);
      setLoading(false);
    } catch (e: any) {
      handleErrorLog(e);
      if (e.message.includes('not found')) {
        navigate('404');
      }
      setError(true);
      setLoading(false);
      return;
    }
  }

  function isValidProduct(slug: string) {
    // Only itau cards are allowed
    return slug.includes('itau') && slug.includes('cards');
  }

  function getOfferDetails({
    product,
    suggestion,
  }: StoryblokProduct): OfferDetail | null {
    if (!product || !suggestion) return null;

    const offerDetailsContent: OfferDetail = {
      slug: suggestion?.slug,
      preApproved: Boolean(suggestion?.preApproved),
      limit: Number(suggestion?.limit),
      title: suggestion?.title,
      highlights: suggestion?.highlights_card_iq,
      featureInformation: suggestion?.feature_information,
      btnText: suggestion?.button_text,
      image: product?.image,
      detailsText: product?.product_benefits,
      partner: suggestion?.partner,
      integrationsList: product?.integrations_list,
    };

    return offerDetailsContent;
  }

  function getCompleteOffer(
    slug: string,
    products: any[],
    suggestions: any[]
  ): SHDecisionItem {
    const sbProduct = products.find((x) => x?.product_slug === slug);
    const product = (sbProduct as SBContent) || null;
    if (!product) throw new Error('[OFFER_DETAIL]: Product not found');

    const sbSuggestion = suggestions.find((x) => x.slug === slug);
    const suggestion = (sbSuggestion as SBContent) || null;
    if (!suggestion) throw new Error('[OFFER_DETAIL]: Suggestion not found');

    // Here, we need this type because is used on redirect function.
    // So, we need to create a new object with the same structure.
    // But, we don't use all the fields, so we can ignore some of them.
    const shDecisionItem: SHDecisionItem = {
      generalInfo: {
        category: suggestion?.category,
        partner: suggestion?.partner,
        id: suggestion?._uid,
        slug: suggestion?.product_slug,
        featured: [product?.feature_information],
        modal: false,
        preApproved: suggestion?.preApproved,
        limit: suggestion?.limit,
      },
      metadata: {}, // We don't use this on redirect
      content: {
        highlights: product.highlights as any[],
        _uid: suggestion._uid,
        button_text: suggestion.button_text,
        category: suggestion.category,
        component: suggestion.component,
        description: suggestion.description,
        feature_information: suggestion.feature_information,
        full_funnel: product?.full_funnel || false,
        highlights_ac: [],
        highlights_card_iq: product.highlights_card_iq,
        highlights_iq: product.highlights_iq,
        links: suggestion.links,
        name: suggestion.name,
        partner: suggestion.partner,
        slug: suggestion.product_slug,
        title: suggestion.title,
        subject_to_credit_approval: false,
        image_logo: {
          filename: product?.image?.filename || '',
          alt: product?.image?.alt || '',
        },
        variant: suggestion.variant,
        subtitle: '',
      },
    };

    return shDecisionItem;
  }

  const onClick = useCallback(() => {
    try {
      if (!offer) return;
      const completeOffer = getCompleteOffer(
        offer.slug,
        productList,
        suggestionList
      );
      handleOfferRedirect({
        offer: completeOffer,
        position: 0,
        location: 'detalhes-oferta',
      });
    } catch (error) {
      handleErrorLog(error);
      setError(true);
    }
  }, [productList, suggestionList, offer]);

  return {
    loading,
    error,
    offer,
    onClick,
    loadingRedirect,
    redirectModal,
  };
};
