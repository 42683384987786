import { useLocation, useNavigate } from 'react-router-dom';

import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { useEffect, useRef, useState } from 'react';
import { useApiScore } from '@tally/apis/score/useApiScore';
import { Notification } from '@tally/types';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { useRedline } from '@libs/redline';
import path from 'path';

export const useHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { signOut } = useUserContext();
  const { elementClickedEvent } = useTracking();
  const showBackButton = !pathname.includes('home');
  const [showMenu, setShowMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [noReads, setNoReads] = useState(false);
  const { track: trackRedline } = useRedline();

  const { notificationRequest, notificationMarkAsRead, notificationDelete } =
    useApiScore();

  useEffect(() => {
    document.body.style.overflow =
      showNotifications || showMenu ? 'hidden' : 'auto';
  }, [showNotifications, showMenu]);

  useEffect(() => {
    getNotifications();
  }, []);

  const isFirstTime = useRef(true);

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    noReadsCount();
  }, [notifications]);

  useEffect(() => {
    if (showNotifications) {
      trackRedline.userTracking.elementViewed({
        location: window.location.pathname,
        elementType: 'content',
        name: 'notifications',
        text: 'visualizou as notificações',
        data: notifications,
      });
    }
  }, [showNotifications]);

  async function getNotifications() {
    try {
      const { data } = await notificationRequest.send();
      setNotifications(data || []);
    } catch (error) {
      handleErrorLog(error);
    }
  }

  function noReadsCount() {
    const noReadsCount = notifications.filter(
      (notification) => notification.read === false
    );

    if (window.isNativeApp) {
      const message = {
        action: 'syncNotificationsBadge',
        payload: { count: noReadsCount.length },
      };
      const stringifiedMessage = JSON.stringify(message);
      window.ReactNativeWebView.postMessage(stringifiedMessage);
    }

    setNoReads(noReadsCount.length > 0);
  }

  async function markAsReadNotification(notification: Notification) {
    const uuid = notification.uuid;

    if (notification.read === true) return;

    try {
      await notificationMarkAsRead.send({ uuid });
      setNotifications((prev) =>
        prev.map((n) => (n.uuid === uuid ? (n.read = true) : n, n))
      );
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function markAllAsReadNotification() {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: 'notifications',
      text: 'marcou todas como lidas',
      data: notifications,
    });

    if (!noReads) return;
    try {
      await notificationMarkAsRead.send();
      setNotifications((prev) => prev.map((n) => ((n.read = true), n)));
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function deleteNotification(notification: Notification) {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: notification.title,
      uuid: notification.uuid,
      category: notification.category,
      text: `excluiu a notificação`,
      path: notification.path || '',
    });
    try {
      await notificationDelete.send({ uuid: notification.uuid });
      setNotifications((prev) =>
        prev.filter((n) => n.uuid !== notification.uuid)
      );
    } catch (error) {
      handleErrorLog(error);
    }
  }

  async function handleNavigateNotification(notification: Notification) {
    trackRedline.userTracking.elementClicked({
      location: window.location.pathname,
      name: notification.title,
      uuid: notification.uuid,
      category: notification.category,
      text: `clicou na notificação`,
      path: notification.path || '',
    });

    await markAsReadNotification(notification);

    if (notification.category === 'generic' && !notification.path) return;

    const redirectMap = {
      score: RouteMap.ObjectivesHistoric,
      objective: RouteMap.Objectives,
      'negative-debts': RouteMap.NegativeDebts,
      lookout: RouteMap.CpfLookout,
      debts: RouteMap.MyDebts,
      offers: RouteMap.Offer,
    };

    notification.category === 'generic'
      ? window.open(notification.path, '_blank')
      : navigate(redirectMap[notification.category]);
  }

  function toogleMenu() {
    setShowMenu(!showMenu);
  }

  function toogleNotifications() {
    setShowNotifications(!showNotifications);
  }

  function handleGoBack() {
    elementClickedEvent({
      elementType: 'button/icon',
      text: '<-',
      location: 'navbar',
    });
    window.history.back();
  }

  function handleNavigate(slug: RouteMap, title: string) {
    elementClickedEvent({
      elementType: 'button/text',
      name: slug,
      text: title,
      location: 'navbar',
    });
    slug === RouteMap.Logout ? signOut(true) : navigate(slug);
  }

  return {
    notifications,
    noReads,
    showNotifications,
    showBackButton,
    showMenu,
    handleGoBack,
    handleNavigate,
    toogleMenu,
    toogleNotifications,
    handleNavigateNotification,
    markAllAsReadNotification,
    deleteNotification,
  };
};
