import { DefaultFormEventPayload } from './types';

const formBrand = 'consumidor-positivo';

export const formContextMap: DefaultFormEventPayload = {
  validation: {
    formContext: {
      formBrand,
      formName: 'validation',
      formVersion: 'v1',
    },
  },
  signIn: {
    formContext: {
      formBrand,
      formName: 'signIn',
      formVersion: 'v1',
    },
  },
  'sign-up': {
    formContext: {
      formBrand,
      formId: 'consumidor-positivo-sign-up',
      formName: 'sign-up',
      formType: 'sign-up',
      formVersion: 'v1',
    },
  },
  forgotPassword: {
    formContext: {
      formBrand,
      formName: 'forgotPassword',
      formVersion: 'v1',
    },
  },
  resetPassword: {
    formContext: {
      formBrand,
      formName: 'resetPassword',
      formVersion: 'v1',
    },
  },
  changePassword: {
    formContext: {
      formBrand,
      formName: 'changePassword',
      formVersion: 'v1',
    },
  },
  changeEmail: {
    formContext: {
      formBrand,
      formName: 'changeEmail',
      formVersion: 'v1',
    },
  },
};

export const shContext = {
  partner: 'newco',
  workflowId: 'cp-tree',
};
