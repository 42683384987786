import './styles.scss';
import { Conditional } from 'iq-blueberry';
import { icons } from './icons';
import { RouteMap } from '@common/constants/RouteMap';
import { Container } from '../Container';
import { useHeader } from './hook';
import { Menu } from './components/Menu';
import { Notifications } from './components/Notifications';
interface HeaderProps {
  username?: string;
}

export const LoggedInHeader: React.FC<HeaderProps> = ({ username }) => {
  const {
    notifications,
    showBackButton,
    showMenu,
    showNotifications,
    noReads,
    handleGoBack,
    handleNavigate,
    toogleMenu,
    toogleNotifications,
    handleNavigateNotification,
    markAllAsReadNotification,
    deleteNotification,
  } = useHeader();

  return (
    <>
      <header className="header">
        <Container customClass="header__navbar">
          <div className="header__navbar-left">
            <Conditional
              condition={showBackButton}
              renderIf={
                <img
                  className="header__navbar-back"
                  src={icons.back.src}
                  alt={icons.back.alt}
                  onClick={handleGoBack}
                ></img>
              }
            />
            <img
              className="header__navbar-logo"
              src={icons.logo.src}
              alt={icons.logo.alt}
              onClick={() => handleNavigate(RouteMap.Dashboard, 'Dashboard')}
            ></img>
          </div>
          <div className="header__navbar-right">
            <img
              className="header__navbar-icons"
              src={noReads ? icons.notifyNews.src : icons.notify.src}
              alt={icons.notify.alt}
              onClick={toogleNotifications}
            ></img>
            <img
              className="header__navbar-icons"
              src={icons.hamburguer.src}
              alt={icons.hamburguer.alt}
              onClick={toogleMenu}
            ></img>
          </div>
        </Container>
      </header>
      <Menu
        name={username || ''}
        showMenu={showMenu}
        profileEdit={() => handleNavigate(RouteMap.UserProfile, 'Meu Perfil')}
        navClick={(RouteMap, title) => handleNavigate(RouteMap, title)}
        onClose={toogleMenu}
      />
      <Notifications
        showNotifications={showNotifications}
        onClose={toogleNotifications}
        notifications={notifications}
        navClick={(notification) => handleNavigateNotification(notification)}
        markAllAsRead={markAllAsReadNotification}
        deleteNotification={deleteNotification}
      />
    </>
  );
};
