import { PrivateDefaultTemplate } from '@common/Templates/PrivateDefault';
import { Button, Conditional, Text } from 'iq-blueberry';

import { Container } from '@common/components/Container';

import { HubTag } from '@hub/components/HubCard/components/HubTag';
import { HubHighlights } from '@hub/components/HubCard/components/HubHighlights';

import { Error } from '@common/components/Error';
import { RedirectModal } from '@hub/components/RedirectModal';
import { LoaderCircular } from '@common/components/LoaderCircular';

import InfoAccordion from './components/InfoAccordion';
import { useOfferDetails } from './hook';
import './styles.scss';

export const OfferDetails = () => {
  const { error, loading, loadingRedirect, offer, onClick, redirectModal } =
    useOfferDetails();

  if (loading) {
    return (
      <PrivateDefaultTemplate>
        <LoaderCircular fullPage />
      </PrivateDefaultTemplate>
    );
  }

  if (error) {
    return (
      <PrivateDefaultTemplate>
        <Container>
          <Error variant="dados" title="Informação não encontrada" />
        </Container>
      </PrivateDefaultTemplate>
    );
  }

  return (
    <PrivateDefaultTemplate>
      <Container>
        <Conditional
          condition={!offer}
          renderIf={<Error variant="dados" title="Informação não encontrada" />}
          renderElse={
            <div className="offer-details">
              <div className="offer-details__image">
                <img
                  src={offer?.image?.filename}
                  alt={offer?.image?.alt || offer?.partner}
                />
              </div>
              <div className="offer-details__title-container">
                <Text
                  className="offer-details__title"
                  as="h2"
                  variant="heading-five"
                  variantDesk="heading-two"
                  defaultColor="title"
                >
                  {offer?.title || ''}
                </Text>
                <HubTag
                  isPreApproved={offer?.preApproved}
                  limit={offer?.limit}
                  featureInformation={offer?.featureInformation}
                />
              </div>

              <HubHighlights
                className="offer-details__highlights"
                topics={offer?.highlights || []}
              />

              <Button
                color="default"
                disabled={false}
                expand="x"
                justify="center"
                loading={loadingRedirect}
                onClick={onClick}
                size="medium"
                style={{ fontSize: '16px', fontWeight: 700 }}
                type="primary"
              >
                {offer?.btnText}
              </Button>

              <InfoAccordion info={offer?.detailsText} />

              <Button
                color="default"
                disabled={false}
                expand="x"
                justify="center"
                loading={false}
                onClick={() => window.history.back()}
                size="medium"
                style={{ fontSize: '16px', fontWeight: 700 }}
                type="secondary"
              >
                Voltar
              </Button>
            </div>
          }
        />
      </Container>

      <RedirectModal
        isOpen={redirectModal.isOpen}
        partnerName={redirectModal.name}
        offerImage={redirectModal.image}
      />
    </PrivateDefaultTemplate>
  );
};
