import { useEffect } from 'react';
import { Conditional } from 'iq-blueberry';
import 'iq-blueberry/src/core/components/legacy/FooterClassic.styl';
import { useSH } from '@libs/sortinghat';

import { LoggedInHeader } from '@common/components/LoggedInHeader';
import { LoggedOutHeader } from '@common/components/LoggedOutHeader';
import { Footer } from '@common/components/Footer';
import { useUserContext } from '@common/contexts/UserContext';
import { useTracking } from '@common/hooks/useTracking';

import './styles.scss';
import { useSortingHat } from '@common/hooks/useSortingHat';
interface IPrivateDefaultProps {
  children: React.ReactNode;
}

export const PrivateDefaultTemplate: React.FC<IPrivateDefaultProps> = ({
  children,
}) => {
  const { user, getUser } = useUserContext();
  useSortingHat({ immediate: true, decideDelayInSeconds: 2 });

  useEffect(() => {
    getUser();
  }, [user]);

  return (
    <>
      <Conditional
        condition={!!user}
        renderIf={<LoggedInHeader username={user?.full_name} />}
        renderElse={<LoggedOutHeader />}
      />

      <main className="private-default-template">{children}</main>
      <Footer />
    </>
  );
};
