import './styles.scss';
import classNames from 'classnames';
import { Notification } from '@tally/types';
import { icons, notifyIcons } from '../../icons';
import { Conditional, Text } from 'iq-blueberry';
import { useEffect, useState } from 'react';
interface NotificationsProps {
  showNotifications: boolean;
  notifications: Notification[];
  onClose: () => void;
  navClick: (notification: Notification) => void;
  markAllAsRead: () => void;
  deleteNotification: (notification: Notification) => Promise<void>;
}

export const Notifications: React.FC<NotificationsProps> = ({
  showNotifications,
  notifications,
  onClose,
  navClick,
  markAllAsRead,
  deleteNotification,
}) => {
  const [news, setNews] = useState<Notification[]>([]);
  const [olds, setOlds] = useState<Notification[]>([]);

  useEffect(() => {
    orderNotificationsByRecent();
  }, [notifications]);

  function orderNotificationsByRecent() {
    const orderedNotifications = orderNotificationsByDate();
    setNews(orderedNotifications.filter((n) => n.recent));
    setOlds(orderedNotifications.filter((n) => !n.recent));
  }

  function orderNotificationsByDate() {
    return notifications.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  function close(e: EventTarget) {
    if (e === document.getElementById('notifications')) onClose();
  }

  function formatDate(date: string) {
    const dateObject = new Date(date);
    const day = dateObject.toLocaleDateString('pt-BR', {
      day: '2-digit',
      timeZone: 'UTC',
    });
    const month = dateObject.toLocaleDateString('pt-BR', {
      month: 'short',
      timeZone: 'UTC',
    });

    return `${day}/${month}`;
  }

  return (
    <div
      className={`notifications ${
        showNotifications ? 'notifications-open' : 'notifications-close'
      }`}
      id="notifications"
      onClick={(e) => close(e.target)}
    >
      <div
        className={`notifications__card ${
          showNotifications ? 'card-open' : 'card-close'
        }`}
      >
        <div className="notifications__header">
          <Text as="h1" variant="heading-five">
            Notificações
          </Text>
          <img
            className="notifications__close"
            src={icons.close.src}
            alt={icons.close.alt}
            onClick={onClose}
          ></img>
        </div>
        <div className="notifications__body">
          <div className="notifications__category">
            <Text as="h2" variant="body-medium">
              <strong>Recentes</strong>
            </Text>
            <Conditional
              condition={notifications.length > 0}
              renderIf={
                <div
                  className="notifications__link"
                  role="button"
                  onClick={markAllAsRead}
                >
                  Marcar todas como lídas
                </div>
              }
            />
          </div>
          <Conditional
            condition={news?.length > 0}
            renderIf={
              <>
                {news.map((n) => (
                  <div
                    key={n.uuid}
                    className={classNames('notifications__item', {
                      'notifications__item--unread': !n.read,
                    })}
                  >
                    <div
                      className="notifications__item__nav"
                      onClick={() => navClick(n)}
                    >
                      <Conditional
                        condition={!n.read}
                        renderIf={
                          <img
                            src={notifyIcons.noRead.src}
                            alt={notifyIcons.noRead.alt}
                          />
                        }
                      />
                      <img
                        src={notifyIcons[n.category].src}
                        alt={notifyIcons[n.category].alt}
                      />
                      <Text as="h5" variant="body-medium">
                        {n.title}
                      </Text>
                    </div>
                    <div className="notifications__item__action">
                      <Text as="p" variant="body-micro">
                        {formatDate(n.date)}
                      </Text>
                    </div>
                  </div>
                ))}
              </>
            }
            renderElse={
              <div className="notifications__empty">
                <img src={notifyIcons.empty.src} alt={notifyIcons.empty.alt} />
                <Text as="h3" variant="body-medium">
                  Você não tem mensagens recentes!
                </Text>
              </div>
            }
          ></Conditional>

          <div className="notifications__category">
            <Text as="h2" variant="body-medium">
              <strong>Antigas</strong>
            </Text>
          </div>
          <Conditional
            condition={olds?.length > 0}
            renderIf={
              <>
                {olds.map((o) => (
                  <div
                    key={o.uuid}
                    className={classNames('notifications__item', {
                      'notifications__item--unread': !o.read,
                    })}
                  >
                    <div
                      className="notifications__item__nav"
                      onClick={() => navClick(o)}
                    >
                      <Conditional
                        condition={!o.read}
                        renderIf={
                          <img
                            src={notifyIcons.noRead.src}
                            alt={notifyIcons.noRead.alt}
                          />
                        }
                      />
                      <img
                        src={notifyIcons[o.category].src}
                        alt={notifyIcons[o.category].alt}
                      />
                      <Text as="h5" variant="body-medium">
                        {o.title}
                      </Text>
                    </div>
                    <div className="notifications__item__action">
                      <Text as="p" variant="body-micro">
                        {formatDate(o.date)}
                      </Text>
                      <div
                        className="notifications__link"
                        role="button"
                        onClick={() => deleteNotification(o)}
                      >
                        Excluir
                      </div>
                    </div>
                  </div>
                ))}
              </>
            }
            renderElse={
              <div className="notifications__empty">
                <img src={notifyIcons.empty.src} alt={notifyIcons.empty.alt} />
                <Text as="h3" variant="body-medium">
                  Você não tem mensagens antigas!
                </Text>
              </div>
            }
          ></Conditional>
        </div>
      </div>
    </div>
  );
};
