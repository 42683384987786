import cpBack from '@common/assets/icon/chevron-left-cp.svg';
import cpLogo from '@common/assets/logo-cp.svg';
import logoCpAc from '@dues/assets/logo-cp-ac.svg';
import cpHamburguer from '@common/assets/icon/hamburguer-cp.svg';
import cpNotify from '@common/assets/icon/notifications/notify.svg';
import cpClose from '@common/assets/icon/close-cp.svg';
import cpNotifyNews from '@common/assets/icon/notifications/notify-news.svg';

import scoreIcon from '@common/assets/icon/notifications/score.svg';
import debtsIcon from '@common/assets/icon/notifications/debts.svg';
import lookoutIcon from '@common/assets/icon/notifications/lookout.svg';
import offersIcon from '@common/assets/icon/notifications/offers.svg';
import negativeDebtsIcon from '@common/assets/icon/notifications/negative-debts.svg';
import objectivesIcon from '@common/assets/icon/notifications/objective.svg';
import genericIcon from '@common/assets/icon/notifications/generic.svg';
import noRead from '@common/assets/icon/notifications/no-read.svg';
import empty from '@common/assets/icon/notifications/empty.svg';

export const icons = {
  logo: { src: logoCpAc, alt: 'Home' },
  back: { src: cpBack, alt: 'Voltar para página anterior' },
  notify: { src: cpNotify, alt: 'Notificação' },
  notifyNews: { src: cpNotifyNews, alt: 'Notificação com bola vermelha' },
  hamburguer: { src: cpHamburguer, alt: 'Menu' },
  close: { src: cpClose, alt: 'Fechar' },
};

export const notifyIcons = {
  score: { src: scoreIcon, alt: 'Velocímetro' },
  debts: { src: debtsIcon, alt: 'Uma cédula de dinheiro' },
  lookout: { src: lookoutIcon, alt: 'Uma lupa' },
  offers: { src: offersIcon, alt: 'Um cartão de crédito' },
  'negative-debts': {
    src: negativeDebtsIcon,
    alt: 'Circulo com exclamação dentro',
  },
  objective: { src: objectivesIcon, alt: 'Uma estrela' },
  generic: { src: genericIcon, alt: 'Um balão de chat' },
  noRead: { src: noRead, alt: 'Uma bola vermelha' },
  empty: { src: empty, alt: 'Um sino com um traço' },
};
