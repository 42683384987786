import logoCP from '../../assets/logo-cp-text.svg';
import pciCertificate from '../../assets/images/pci-certificate.webp';
import reclameAquiCertificate from '../../assets/images/reclame-aqui-certificate.webp';
import sslCertificate from '../../assets/images/ssl-certificate.webp';
import AppStore from '@common/assets/app-store-banner.svg';
import GooglePlay from '@common/assets/google-play-banner.svg';

import { AppStoresModal } from '@common/components/AppStoresModal';

import useFooter from './hook';
import { linksGroup } from './data';
import './styles.scss';

export const Footer = () => {
  const { handleClick, handleStoreRedirect, isModalOpen, setIsModalOpen } =
    useFooter();

  return (
    <footer className="footer">
      <div className="footer__container">
        <section className="footer__logo">
          <img
            src={logoCP}
            alt="Logo do consumidor positivo em azul e verde com consumidor positivo escrito ao lado em preto"
          />
        </section>
        <section className="footer__links">
          <nav className="footer__navigation">
            {linksGroup.map((group, indexGroup) => (
              <div key={indexGroup} className="footer__links-group">
                <h2 className="footer__links-group__title">{group.title}</h2>
                <ul className="footer__links-group__list">
                  {group.links.map((link, indexLink) => (
                    <li key={indexLink}>
                      <a
                        href={link.url}
                        target={link.target}
                        rel="noreferrer"
                        data-category={group.title}
                        className="footer__links-group__link"
                        onClick={handleClick}
                      >
                        {link?.text && link.text}
                        {link?.image && (
                          <img
                            data-category={group.title}
                            data-src={link.image.src}
                            src={link.image.src}
                            alt={link.image.alt}
                          />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
        </section>
        <hr className="footer__divider" />
        <section className="footer__company">
          <div>
            <p className="footer__text">Consumidor Positivo Ltda.</p>
            <p className="footer__text">CNPJ: 30.247.128/0001-15</p>
          </div>
          <div className="footer__certificates">
            <img src={sslCertificate} alt="SSL Blindado" />
            <img src={reclameAquiCertificate} alt="Ótimo Reclame Aqui" />
            <img src={pciCertificate} alt="PCI" />
          </div>
        </section>
        <hr className="footer__divider" />
        <section className="footer__copyright">
          <div
            onClick={handleStoreRedirect}
            className="footer__copyright__stores"
          >
            <img src={GooglePlay} alt="Google Play" />
            <img src={AppStore} alt="App Store" />
          </div>
          <p className="footer__text">
            © 2024 Consumidor Positivo Ltda. - Todos os direitos reservados
          </p>
        </section>
      </div>

      <AppStoresModal
        showModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </footer>
  );
};
